/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        // Remove text nodes
        function clean(node)
        {
          for(var n = 0; n < node.childNodes.length; n ++)
          {
            var child = node.childNodes[n];
            if
            (
              child.nodeType === 8 ||
              (child.nodeType === 3 && !/\S/.test(child.nodeValue))
            )
            {
              node.removeChild(child);
              n --;
            }
            else if(child.nodeType === 1)
            {
              clean(child);
            }
          }
        }
        clean(document);
        // Scroll to top on click
        $('.back-to-top').click(function(){
          $('html, body').stop().animate({ scrollTop: 0 }, 500);
        });
        // Fade scroll to top in/out on scroll
        $(document).scroll(function() {
          var y = $(this).scrollTop();
          if (y > 800) {
            $('.back-to-top').fadeIn(500);
          } else {
            $('.back-to-top').fadeOut(500);
          }
        });
        // Show hidden menu
        $('.menu-btn').click(function(){
          $('.fixed-menu').addClass('right-reset');
          $('.fixed-menu').focus();
        });
        // Hide hidden menu
        $('.fixed-menu .close-btn').click(function() {
          $('.fixed-menu').blur();
        });
        $(window).scroll(function(){
          $('.fixed-menu').blur();
        });
        $('.fixed-menu').blur(function() {
          $(this).delay(200).queue(function() {
            $(this).removeClass('right-reset');
            $(this).clearQueue();
          });
        });
        // Match Height
        $(function() {
          $('.article-col').matchHeight();
          $('.featured-articles .post-holder .read-article').matchHeight();
          $('.ajax-div .article-col').matchHeight();
        });
        $(document).ready(function(){
          $('.ajax-div .article-col').matchHeight();
        });
        $(document).ajaxComplete(function(){
        	$('.ajax-div .article-col').matchHeight();
        });
        jQuery('#load-more').click(function(){
          return false;
        });
        // Scroll page to sign up form
        $('.mail-list a').click(function() {
          $('html, body').animate({scrollTop: $('.lower-mailing-list').offset().top}, 500);
          return false;
        });
        // Remove Share buttons
        $('.shareaholic-share-buttons-container.floated').remove();
        // Toggle Search height
        $('.search-btn').click(function() {
          $('.hidden-search').toggleClass('height-reset');
        });
        $('.close-search').click(function(){
          $('.hidden-search').removeClass('height-reset');
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
        $(function() {
          $('.wwd-block').matchHeight();
          $('.value h4').matchHeight();
        });

      }
    },
    'contact': {
      init: function() {
        // JavaScript to be fired on the about us page
        $(function() {
          $('.contact-section').matchHeight();
        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
